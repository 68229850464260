/**
 * RoleCode Enumerator, contains all static role codes in Datalex
 */
export enum RoleCode {
  ISA = "21321311-0000-0000-0000-000000000089",
  ISB = "21321311-0000-0000-0000-000000000090",
  KR = "21321311-0000-0000-0000-000000000091",
  KH = "21321311-0000-0000-0000-000000000095",
  BO = "21321311-0000-0000-0000-000000000096",
  ISE = "47CEE16C-C82B-4970-BA6E-0A648ED487D9",
  MK = "7B74C16A-17AD-4716-8123-1101D024DC44",
  M2 = "2B91EC15-7F90-4005-823E-171542AA2B6F",
  A8 = "D2F70D4C-49D3-41FE-B67B-19798383B7EC",
  M8 = "8667B837-947E-4F8D-9CCB-25ECAACA0A3F",
  K8 = "4E1F8862-D35A-4950-B7D5-27D279BF22A4",
  A9 = "B785C383-17D1-4D91-8124-28BFD913612E",
  A5 = "41488592-6B26-4D6E-A0F5-2D8142428A47",
  K6 = "887F80A5-407B-40A5-8078-2E76503AA7AB",
  R3 = "67FD08CD-21DC-4BAD-9AAF-34CFB858A1A1",
  M5 = "8CE190FF-0823-49AF-A482-3F67B7C2B65F",
  KT = "F4E216A0-73A7-46DD-BF1D-4B03F4A75961",
  M7 = "6CD355F4-2310-48BE-BE62-5118E109BC35",
  DI = "E6CFE4DC-6ED2-4F5E-B5F3-542A5F13A144",
  MT = "3A06B892-8E47-4165-8E48-5E40E4975231",
  K2 = "5F20F04E-32ED-4A5D-A451-6493D44FFD17",
  A7 = "D6905A0E-5CCD-4AFE-8A06-69BE64FE68DB",
  A2 = "2D19886F-E7DF-460B-93C3-6A3CBC3FC757",
  AK = "EF8071D3-68AE-40F3-96AB-805C99EADCAB",
  A4 = "F6B0C782-3492-4FF8-AD41-835DD68DD2C4",
  M3 = "7F2529BE-AF19-4CEB-B194-87AF5D2BDBAB",
  M6 = "DF7A91B0-8BDA-44AB-886E-8C2CF11D9891",
  A6 = "35471BB5-349D-4D23-B4FF-8EEB51F5BCEC",
  M4 = "AFC65563-D71F-4572-AD59-9556DD13DD82",
  BA = "FD75283A-8344-4D13-84F8-968F72B583E2",
  ITB = "C17C3E54-DEFC-4041-8A9A-97DE454FFAFB",
  MV = "3FC1296A-9C4A-4C09-8D9C-9E88B1247328",
  KV = "CCD0578F-909C-4453-B1EC-9FBE1797A2DF",
  R4 = "A6702C99-2AAC-4DD6-8FA8-A19A16F4D8E3",
  KA = "A8A18F57-4BB5-4BA0-BB75-A22BB6DD1377",
  K3 = "EC81656C-CF26-4B6B-8E4A-A6AC95ED9B1A",
  K9 = "FBD9E182-F178-4ABA-B651-AF391CCB3553",
  MH = "5A95E872-8DDD-4D59-961E-AFC74DC8D5BC",
  M9 = "11774D6E-DA62-4F9F-B60F-B41AAABB61EB",
  FA = "20BA81B7-DFBF-453C-A178-B42EBD8338BF",
  A3 = "FEDCF048-A874-4D83-A63F-B9B99F33C989",
  BK = "747E35EE-792D-4663-AF8A-BFD78D627139",
  A = "28D67778-1ADF-4045-AD95-C0C072FB32F1",
  IDI = "650A47FA-9F4A-445B-B1D3-C11A3681F6A2",
  R2 = "05E24431-6664-44C9-9B59-C94F6B0F4449",
  K7 = "7F69968A-0CC0-4239-92E3-CC2C351534EA",
  BM = "9C553687-027C-4B49-8295-CE371ACE071E",
  KF = "C20D362A-D563-4EBD-8EA8-E00F1E0CBCD4",
  K4 = "A544EDA4-C0A3-43C5-BE7E-E58064C238D6",
  KK = "7E942B95-7615-4DA9-B678-ED17306EB728",
  K5 = "235DBB90-76E0-47B1-A086-F7B63315536F",
  RI = "BD1B4E0A-8D8B-4D17-8A62-FAC50626987B"
}


export enum RoleTypeCategoryIdEnum {
  INTERN = '6034096E-1574-4571-8B9A-4C663A1AF63E',
  DIVERSE = '60B68D03-88D7-4AA0-A9C4-5003B07CE4C1',
  M_ADVOKAT = 'EDC42D6D-2DB7-4AFE-87C7-6BBC3B9A6B2E',
  BO_KBO = 'EB403D84-9DA5-438C-94D6-7798756DD985',
  MOTPART = '61B6F968-64E6-4DA8-93ED-B78DAB2CF3A0',
  KLIENT = '1CBFFCE0-C0F0-4B90-9437-ECFBE08D96CF',
  RETTSINSTANS = '5D702FA4-7E8A-42D5-8F71-F2EDD7C3CAF4',
}




enum INTERN {
  ISA = "21321311-0000-0000-0000-000000000089",
  ISB = "21321311-0000-0000-0000-000000000090",
  ISE = "47CEE16C-C82B-4970-BA6E-0A648ED487D9",
  ITB = "C17C3E54-DEFC-4041-8A9A-97DE454FFAFB",
  /**
  * Role code for "Andre medarbeidere".
  */
  IDI = "650A47FA-9F4A-445B-B1D3-C11A3681F6A2",
}

enum DIVERSE {
  DI = "E6CFE4DC-6ED2-4F5E-B5F3-542A5F13A144",
  FA = "20BA81B7-DFBF-453C-A178-B42EBD8338BF",
}

enum M_ADVOKAT {
  A8 = "D2F70D4C-49D3-41FE-B67B-19798383B7EC",
  A9 = "B785C383-17D1-4D91-8124-28BFD913612E",
  A5 = "41488592-6B26-4D6E-A0F5-2D8142428A47",
  A7 = "D6905A0E-5CCD-4AFE-8A06-69BE64FE68DB",
  A2 = "2D19886F-E7DF-460B-93C3-6A3CBC3FC757",
  AK = "EF8071D3-68AE-40F3-96AB-805C99EADCAB",
  A4 = "F6B0C782-3492-4FF8-AD41-835DD68DD2C4",
  A6 = "35471BB5-349D-4D23-B4FF-8EEB51F5BCEC",
  A3 = "FEDCF048-A874-4D83-A63F-B9B99F33C989",
  A = "28D67778-1ADF-4045-AD95-C0C072FB32F1",
}

enum BO_KBO {
  BA = "FD75283A-8344-4D13-84F8-968F72B583E2",
  BK = "747E35EE-792D-4663-AF8A-BFD78D627139",
  BM = "9C553687-027C-4B49-8295-CE371ACE071E",
}

enum MOTPART {
  MK = "7B74C16A-17AD-4716-8123-1101D024DC44",
  M2 = "2B91EC15-7F90-4005-823E-171542AA2B6F",
  M8 = "8667B837-947E-4F8D-9CCB-25ECAACA0A3F",
  M5 = "8CE190FF-0823-49AF-A482-3F67B7C2B65F",
  M7 = "6CD355F4-2310-48BE-BE62-5118E109BC35",
  MT = "3A06B892-8E47-4165-8E48-5E40E4975231",
  M3 = "7F2529BE-AF19-4CEB-B194-87AF5D2BDBAB",
  M6 = "DF7A91B0-8BDA-44AB-886E-8C2CF11D9891",
  M4 = "AFC65563-D71F-4572-AD59-9556DD13DD82",
  MV = "3FC1296A-9C4A-4C09-8D9C-9E88B1247328",
  MH = "5A95E872-8DDD-4D59-961E-AFC74DC8D5BC",
  M9 = "11774D6E-DA62-4F9F-B60F-B41AAABB61EB",
}

enum KLIENT {
  KR = "21321311-0000-0000-0000-000000000091",
  KH = "21321311-0000-0000-0000-000000000095",
  BO = "21321311-0000-0000-0000-000000000096",
  K8 = "4E1F8862-D35A-4950-B7D5-27D279BF22A4",
  K6 = "887F80A5-407B-40A5-8078-2E76503AA7AB",
  KT = "F4E216A0-73A7-46DD-BF1D-4B03F4A75961",
  K2 = "5F20F04E-32ED-4A5D-A451-6493D44FFD17",
  KV = "CCD0578F-909C-4453-B1EC-9FBE1797A2DF",
  KA = "A8A18F57-4BB5-4BA0-BB75-A22BB6DD1377",
  K3 = "EC81656C-CF26-4B6B-8E4A-A6AC95ED9B1A",
  K9 = "FBD9E182-F178-4ABA-B651-AF391CCB3553",
  K7 = "7F69968A-0CC0-4239-92E3-CC2C351534EA",
  KF = "C20D362A-D563-4EBD-8EA8-E00F1E0CBCD4",
  K4 = "A544EDA4-C0A3-43C5-BE7E-E58064C238D6",
  KK = "7E942B95-7615-4DA9-B678-ED17306EB728",
  K5 = "235DBB90-76E0-47B1-A086-F7B63315536F",
}


enum RETTSINSTANS {
  R3 = "67FD08CD-21DC-4BAD-9AAF-34CFB858A1A1",
  R4 = "A6702C99-2AAC-4DD6-8FA8-A19A16F4D8E3",
  R2 = "05E24431-6664-44C9-9B59-C94F6B0F4449",
  RI = "BD1B4E0A-8D8B-4D17-8A62-FAC50626987B",
}


/**
* Datastruktur for å få rolleID og rollekatergoriID fra statiske roller;
* eksempel: RoleTypes.INTERN.id gir kategoriens ID
* eksempel: RoleTypes.INTERN.ISB gir Saksbehandler rollens ID
*/

export const RoleTypes = {
  INTERN: {
    id: RoleTypeCategoryIdEnum.INTERN,
    ...INTERN
  },
  DIVERSE: {
    id: RoleTypeCategoryIdEnum.DIVERSE,
    ...DIVERSE
  },
  M_ADVOKAT: {
    id: RoleTypeCategoryIdEnum.M_ADVOKAT,
    ...M_ADVOKAT
  },
  BO_KBO: {
    id: RoleTypeCategoryIdEnum.BO_KBO,
    ...BO_KBO
  },
  MOTPART: {
    id: RoleTypeCategoryIdEnum.MOTPART,
    ...MOTPART
  },
  KLIENT: {
    id: RoleTypeCategoryIdEnum.KLIENT,
    ...KLIENT
  },
  RETTSINSTANS: {
    id: RoleTypeCategoryIdEnum.RETTSINSTANS,
    ...RETTSINSTANS
  }
}



const andre = RoleTypes.INTERN.IDI;

//     // IRoleTypeCategoryBE interface definition
//     export interface RoleTypeCategory extends IRoleTypeCategoryBE{
//         roles: { [key: string]: IRoleTypeBE };
//     }

//     // RoleTypes interface definition
//     export interface RoleTypes {
//         [key: string]: RoleTypeCategory;
//     }







