import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-heading',
    templateUrl: './heading.component.html',
    styleUrls: ['./heading.component.scss'],
    standalone: true
})
export class HeadingComponent implements OnInit {
  constructor() { } 
  @Input() value: string = '';
  ngOnInit(): void {
  }
}
